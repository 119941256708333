<template>
    <tr>
        <td>{{ item.name }}</td>
        <td>{{ item.assetCount }}</td>
        <td v-if="userDetail.userTypeId == 1 || userDetail.userTypeId == 4" class="text-nowrap">
            <MDBTooltip v-model="tooltipSetting">
                <template #reference>
                    <router-link :to="{
                        name: 'AssetLabelSettings',
                        params: { id: item.id },
                    }">
                        <MDBBtn class="me-2" rounded outline="primary" size="sm">
                            <vue-feather type="users" :stroke-width="3" size="16" />
                        </MDBBtn>
                    </router-link>
                </template>
                <template #tip> View Assigned Users </template>
            </MDBTooltip>
            <MDBTooltip v-model="tooltipModal">
                <template #reference>
                    <MDBBtn rounded outline="primary" size="sm" @click.stop="viewAssets(item.id, item.name)">
                        <vue-feather type="film" :stroke-width="2.5" size="16" />
                        <MDBBadge badge="danger" size="xs" class="border border-light rounded-circle position-absolute">
                        </MDBBadge>
                    </MDBBtn>
                </template>
                <template #tip> View Assets </template>
            </MDBTooltip>
        </td>
    </tr>
    <MDBModal class="card-container" id="assetModal" tabindex="-1" labelledby="assetModalLabel" v-model="assetModal"
        size="lg">
        <MDBModalHeader class="px-4 py-3">
            <MDBModalTitle id="assetModalLabel" class="fw-bold">
                {{ selectedLabelAssetsName }}`s Assets
            </MDBModalTitle>
        </MDBModalHeader>
        <MDBModalBody class="d-flex flex-column p-3 pb-0">
            <div class="overflow-auto" id="table-container">
                <MDBTable hover class="align-middle">
                    <thead class="table-light">
                        <tr>
                            <th class="sticky-top" scope="col">Asset ID</th>
                            <th class="sticky-top" scope="col">Title</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(assets, x) in selectedLabelAssetsList.data" :key="x">
                            <td>{{ assets.assetId }}</td>
                            <td v-if="assets.title === ''"><i>(No Title Specified)</i></td>
                            <td v-else>{{ assets.title }}</td>
                        </tr>
                    </tbody>
                </MDBTable>
            </div>
            <div class="overflow-auto">
                <Paginate :pageType="'assetLabelAssets'" :assetLabelId="selectedLabelAssetsId"
                    @paginationData="selectedLabelAssetsList = $event">
                </Paginate>
            </div>
        </MDBModalBody>
        <MDBModalFooter class="py-3 px-4">
            <MDBBtn class="fw-bold" color="primary" size="sm" @click="closeAssetsModal()"> Close </MDBBtn>
        </MDBModalFooter>
    </MDBModal>
</template>

<script setup>
import { ref, defineProps } from "vue";
import { MDBTooltip, MDBModal, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter, MDBBtn, MDBBadge, MDBTable } from "mdb-vue-ui-kit";
import Paginate from "@/components/Pagination/CSDPagination.vue";
import axios from "axios";
import VueFeather from 'vue-feather';

const tooltipSetting = ref(false);
const tooltipModal = ref(false);

const assetModal = ref(false);
defineProps({
    item: Object,
    userDetail: Object,
});

const selectedLabelAssetsList = ref([]);
const selectedLabelAssetsId = ref(null);
const selectedLabelAssetsName = ref("");
const viewAssets = async (id, name) => {
    await axios
        .get(`api/assetlabels/${id}/Assets`)
        .then(function (response) {
            selectedLabelAssetsId.value = id;
            selectedLabelAssetsName.value = name;
            selectedLabelAssetsList.value = response.data;
            assetModal.value = true;
        })
        .catch(function (error) {
            console.log("error", error);
        });
};

const closeAssetsModal = () => {
    selectedLabelAssetsId.value = null;
    selectedLabelAssetsName.value = "";
    selectedLabelAssetsList.value = [];
    assetModal.value = false;
};
</script>

<style scoped>
.btn-primary {
    background-color: var(--primary);
}

.btn-outline-primary {
    border-color: var(--accent);
    color: var(--accent);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    /* width: 30px;
    height: 30px;
    display: grid;
    place-content: center; */
    vertical-align: middle;
}

.btn-outline-primary i {
    vertical-align: top;
}
</style>

<style>
.tooltip-inner {
    font-family: "Poppins", sans-serif !important;
    background-color: var(--primary);
    font-size: 12px;
    padding: 3px 12px;
}

.badge-dot {
    background-color: var(--primary);
}

th {
    text-transform: uppercase;
}

tr {
    cursor: default;
}

.card-container {
    flex: 1 1 auto;
    overflow: auto;
}

#table-container {
    flex: 1 1 auto;
    height: 45vh;
}
</style>