<template>
    <LayoutNext>
        <template #page-title>
            Asset Labels
        </template>
        <MDBCard class="shadow-0 card-container">
            <MDBCardHeader class="d-md-flex d-block justify-content-between py-3 px-0">
                <div class="d-flex justify-content-start">
                    <MDBBtn color="primary" class="mb-md-0 mb-3 fw-bold px-3 py-2" size="sm"
                        @click="assetLabelModal = true" v-if="isAdmin || isSuperAdmin">
                        <MDBIcon iconStyle="fas" icon="folder-plus" class="me-1" />
                        Add
                    </MDBBtn>
                </div>
                <div class="d-flex justify-content-end">
                    <Search :searchType="'assetLabel'" @searchResult="assetData = $event"
                        searchLabel="Search Asset Label" @isLoading="isLoading = $event" />
                </div>
            </MDBCardHeader>
            <MDBCardBody class="d-flex flex-column p-0">
                <div class="overflow-auto mt-3" id="table-container">
                    <MDBTable hover class="align-middle mb-0">
                        <thead class="table-light">
                            <tr>
                                <th class="sticky-top" v-for="(item, i) in tableHeaders" :key="i" scope="col">
                                    {{ item.header }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="isLoading">
                                <tr>
                                    <td colspan="3" class="text-center">
                                        <div class="image-container d-flex justify-content-center align-items-center">
                                            <div class="loader"></div>
                                            <img src="@/assets/cs-loader.png" alt="Loading image">
                                        </div>
                                    </td>
                                </tr>
                            </template>
                            <template v-else>
                                <tr v-if="assetLabelList.data.length === 0">
                                    <td colspan="3" class="text-center py-3">No asset label found.</td>
                                </tr>
                                <template v-else v-for="(item, k) in assetLabelList.data" :key="k">
                                    <AssetLabelTableRowNext :item="item" :userDetail="userDetail">
                                    </AssetLabelTableRowNext>
                                </template>
                            </template>
                        </tbody>
                    </MDBTable>
                </div>
                <div class="overflow-auto">
                    <Paginate :pageType="'assetLabel'" @paginationData="assetLabelList = $event">
                    </Paginate>
                </div>
                <MDBToast v-model="toastError" id="basic-danger-example" :delay="2000" autohide position="top-right"
                    appendToBody stacking width="350px" color="danger" text="white">
                    <template #title> </template>
                    {{ toastMessage }}
                </MDBToast>
            </MDBCardBody>
        </MDBCard>
        <MDBModal id="assetLabelModal" tabindex="-1" labelledby="assetLabel" v-model="assetLabelModal" class="ps-0"
            :static-backdrop="true">
            <MDBModalHeader>
                <MDBModalTitle id="assetLabel" class="fw-bold"> Add Asset Label </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
                <MDBInput v-model="assetLabel" placeholder="Asset Label Name" />
            </MDBModalBody>
            <MDBModalFooter class="gap-2 py-3">
                <MDBBtn @click="assetLabelModal = false" size="sm" class="fw-bold"> Close </MDBBtn>
                <MDBBtn color="primary" size="sm" class="fw-bold" @click="addAssetLabel"
                    :disabled="assetLabel.length == 0">
                    Confirm
                </MDBBtn>
            </MDBModalFooter>
        </MDBModal>
        <MDBToast v-model="toastSuccess" :delay="2000" autohide position="top-right" appendToBody stacking width="350px"
            color="success" text="white">
            <template #title> Success </template>
            {{ assetLabel }} Added successfully!
        </MDBToast>
    </LayoutNext>
</template>

<script setup>
import LayoutNext from "@/views/v3/LayoutNext.vue";
import Search from "@/components/CSDSearch.vue";
import Paginate from "@/components/Pagination/CSDPagination.vue";
import AssetLabelTableRowNext from "./AssetLabelTableRowNext.vue";
import { computed, onBeforeMount, ref, watch } from "vue";
import axios from "axios";
import { PostAssetLabels } from "@/services/AssetLabels/PostAssetLabels";
import { useUserDetailStore } from "@/store/userDetailStore";
import { storeToRefs } from "pinia";
import { MDBCard, MDBCardHeader, MDBCardBody, MDBTable, MDBToast, MDBBtn, MDBInput, MDBIcon, MDBModal, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter } from "mdb-vue-ui-kit";
import { useTitle } from "@vueuse/core";

useTitle("Asset Labels | Creator Shield");

const assetData = ref({
    data: [],
    pagination: {},
});

const userDetailStore = useUserDetailStore();
const { userDetail, isAdmin, isSuperAdmin } = storeToRefs(userDetailStore);

let toastError = ref(false);
let toastMessage = "";

const tableHeaders = computed(() => {
    if (userDetail.value.userTypeId == 1 || userDetail.value.userTypeId == 4) {
        return [
            {
                header: "Asset Label",
            },
            {
                header: "Total # of Assets",
            },
            {
                header: "Actions",
            },
        ];
    } else {
        return [
            {
                header: "Asset Label",
            },
            {
                header: "Total # of Assets",
            },
        ];
    }
});

const assetLabelList = ref({
    data: [],
    pagination: {},
});

watch(assetData, (newAssetLabelList) => {
    assetLabelList.value = newAssetLabelList;
});

const isLoading = ref(false);

onBeforeMount(() => {
    loadAssetLabels()
});

const loadAssetLabels = async () => {
    isLoading.value = true;
    await axios
        .get("api/assetLabels/List")
        .then(function (response) {
            isLoading.value = false;
            assetLabelList.value = response.data;
        })
        .catch(function (error) {
            isLoading.value = false;
            toastError = true;
            toastMessage = "An error occurred during request" + error;
        });
};

const assetLabelModal = ref(false);
const assetLabel = ref("");
const toastSuccess = ref(false);

const addAssetLabel = async () => {
    const response = await PostAssetLabels(assetLabel.value);
    if (response) {
        loadAssetLabels();
        assetLabelModal.value = false;
        toastSuccess.value = true;
        setTimeout(() => {
            assetLabel.value = "";
        }, 1000);
    }
};
</script>

<style scoped>
.table-dark {
    --mdb-table-bg: var(--accent);
    border-color: var(--accent);
}

.btn-primary {
    background-color: var(--primary);
}

.btn-outline-primary {
    border-color: var(--accent);
    color: var(--accent);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

th {
    text-transform: uppercase;
}

tr {
    cursor: default;
}

.card-container {
    flex: 1 1 auto;
    overflow: auto;
}

#table-container {
    flex: 1 1 auto;
    height: 55vh;
}

.image-container {
    position: relative;
    display: inline-block;
}

.image-container .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 40px;
    height: 40px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top-color: rgb(153, 153, 153);
    border-radius: 50%;
    animation: BorderSpin 1s linear infinite;
}

.image-container img {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

@keyframes BorderSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
